import React from 'react'
import classNames from 'classnames'

import Page from '../../templates/Page'
import PageSection from '../../templates/PageSection'

class DMCA extends React.Component {
  render() {
    return (
      <Page
        className={classNames('legal', 'dmca', this.props.className)}
        headerTitle="DMCA Notice"
      >
        <PageSection>
          <p>
            The Company is transmitting Content as intellectual property under
            the DMCA. The DMCA provides recourse for copyright owners who
            believe that material appearing on the Internet infringes their
            rights under U.S. copyright law. The company will promptly process
            and investigate notices of alleged infringement and will take
            appropriate actions under the DMCA and other applicable intellectual
            property laws with respect to any alleged or actual infringement by
            Webcaster.
          </p>
        </PageSection>

        <PageSection>
          <h3>Designated Agent</h3>

          <p>
            For Purposes Of Section 512 Of The Digital Millennium Copyright Act.
            if you have reason to believe that material in which you (or any
            person or entity for which you are an authorized agent) hold the
            copyright to Content without appropriate authorization, please
            contact the Company’s Designated Agent at:
          </p>

          <address>
            <span className="address-line">Live365</span>
            <span className="address-line">1340 Monticello Avenue</span>
            <span className="address-line">Hermitage, PA 16148</span>
            <span className="address-line">privacy@live365.com</span>
          </address>

          <p>Please provide our Agent with the following Notice:</p>

          <ol>
            <li>
              Identify the material on the site that you claim is infringing,
              with enough detail so that we may locate it on the website
            </li>
            <li>
              A statement by you that you have a good faith belief that the
              disputed use is not authorized by the copyright owner, its agent,
              or the law
            </li>
            <li>
              A statement by you declaring under penalty of perjury that (1) the
              above information in your Notice is accurate, and (2) that you are
              the owner of the copyright interest involved or that you are
              authorized to act on behalf of that owner
            </li>
            <li>Your address, telephone number, and email address and</li>
            <li>Your physical or electronic signature.</li>
          </ol>

          <p>
            The Company fully complies with the notice and take-down provisions
            of Sections 512(c) and (g) of the United States Copyright Act.
          </p>
        </PageSection>
      </Page>
    )
  }
}

export default DMCA
